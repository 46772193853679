import { createMuiTheme } from "@material-ui/core"

const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      positionFixed: {
        margin: "auto",
      },
      colorPrimary: {
        backgroundColor: "#144050",
      },
    },
  },
})

export default theme

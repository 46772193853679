import { AggregatedData } from './../Aggregated.interface';
export interface StepMark {
    key: number,
    value: number
}

export const findClosestStepMarkValue = (value: any, stepMarks: any[]) => {
    if (stepMarks.length === 0) {
        return null;
    }
    if (value === '') {
        return null;
    }
    return stepMarks.reduce((prev: StepMark, curr: StepMark) => {
        return (Math.abs(curr.value - value) < Math.abs(prev.value - value) ? curr : prev);
    });
}

export const getClosestStepMarkValues = (value: StepMark, stepMarks: any[]) => {
    if(value === null) {
        return stepMarks;
    }

    let numberOfEntries = 20;
    let startIndex = value.key;

    if (stepMarks.length < 10) {
        numberOfEntries = stepMarks.length;
    }

    if (startIndex < (numberOfEntries / 2)) {
        startIndex = 0;
    } else if (startIndex + numberOfEntries >= (stepMarks.length - 1)) {
        startIndex = stepMarks.length - (numberOfEntries - 1)
    } else {
        startIndex = startIndex - Math.floor(numberOfEntries / 2);
    }

    return stepMarks.slice(startIndex, (startIndex + numberOfEntries));

}

export const mapSliderValue = (sliderValue: number, stepMarks: StepMark[] = []): number => {
    const stepMark = stepMarks.find((stepMark: StepMark) => stepMark.key === sliderValue);
    if (stepMark !== undefined) {
        return stepMark.value;
    }
    return 0;
}

export const sliderRangeToValues = (sliderValues: number[], stepMarks: StepMark[] = []): number[] => {
    return [mapSliderValue(sliderValues[0], stepMarks), mapSliderValue(sliderValues[1], stepMarks)];
}

export const createMarks = (dataSets: AggregatedData): StepMark[] => {
    let temp: number[] = [];
    let output: StepMark[] = [];
    Object.values(dataSets).forEach(dataSet => {
        Object.keys(dataSet.mappedEmployees).forEach((data, index) => {
            const value = parseInt(data, 10);
            const found = temp.some(el => el === value);
            if (!found) temp.push(value);
        });
    });

    if(temp.length === 0) {
        output = defaultMarks.slice();
    }
    temp = temp.sort((a,b) => a - b );

    temp.forEach((el: number, index) => {
        output.push({key: index, value: el});
    })

    return output;
}

export const defaultMarks = [
    {key:0, value: 0 },
    {key:1, value: 0}
]


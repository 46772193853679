
var SELECTED_RULESET = 0;
const FROM = 0;
const TO = 1;
const MAXVALUE = 99999; // Should been infinity but that is not an option

const rules: number[][][] = [
    [[0, 5], [6, 10], [11, 20], [21, 50], [51, 100], [101, 500], [501, MAXVALUE]],
    [[0, 0], [1, 1], [2, 5], [6, 10], [11, 20], [21, 50], [51, 100], [101, 500], [501, MAXVALUE]],
    [[1, 1], [2, 5], [6, 10], [11, 20], [21, 50], [51, 100], [101, 500], [501, MAXVALUE]],
    [[2, 3], [4, 5], [6, 7], [8, 10], [11, 20], [21, 50], [51, 100], [101, 500], [501, MAXVALUE]],
    [[5, 9], [10, 15], [16, 20], [21, 25], [26, 50], [51, 100], [101, 500], [501, MAXVALUE]],
    [[10, 20], [21, 30], [31, 40], [41, 50],  [51, 65], [66, 80], [81, 100], [101, 500], [501, MAXVALUE]],
    [[20, 30], [31, 40], [41, 50],  [51, 60], [61, 70], [71, 80], [81, 90], [91, 100], [101, 500], [501, MAXVALUE]],
    [[0, 4], [5, 9], [10, 15], [16, 20], [21, 25], [26, 50], [51, 100], [101, 500]],
    [[10, 20], [20, 30], [31, 40], [41, 2], [3, 3], [4, 4], [5, 10], [11, 15], [16, 20], [101, 250], [251, 500]],
    [[20, 30], [31, 40], [41, 50],  [51, 60], [61, 70], [71, 80], [81, 90], [91, 100], [101, 250], [251, 500]],
    [[10, 20], [21, 30], [31, 40], [41, 50], [51, 60], [61, 70], [71, 80], [81, 90], [91, 100], [101, 250]],
    [[0, 1], [2, 3], [4, 5], [6, 7], [8, 10], [11, 20], [21, 50], [51, 100], [101, 250]],
    [[0, 1], [2, 3], [4, 5], [6, 7], [8, 10], [11, 20], [21, 30], [31, 50], [51, 100]],
    [[5, 9], [10, 15], [16, 20], [21, 25], [26, 50], [51, 75], [76, 100], [101, 500]],
    [[5, 9], [10, 15], [16, 20], [21, 25], [26, 30], [31, 40], [41, 50], [51,60], [61,75], [76, 100]],
    [[0, 1], [2, 3], [4, 5], [6, 7], [8, 10], [11, 20], [21, 30], [31, 50], [51, 75]],
    [[0, 0],[1, 1], [2,2], [3, 3], [4, 5], [6, 7], [8, 10], [11, 20], [21, 30], [31, 40], [41, 50]],
    [[0, 0], [1, 1], [2, 2], [3, 3], [4, 4], [5, 10], [11, 15], [16, 20], [21, 25]],
    [[0, 0], [1, 1], [2, 2], [3, 3], [4, 4], [5, 5], [6, 6], [7, 7], [8, 8], [9, 9], [10, 10]],
]

interface RuleCandidate {
    ruleId: number,
    low: number,
    lowDiff: number,
    lowRule: number[],
    high: number,
    highDiff: number,
    highRule: number[]
}

const chooseCandidate = ((ruleCandidates: RuleCandidate[] = []): number => {
    if(ruleCandidates.length === 0) {
        return 0;
    }

    const lowest = ruleCandidates.reduce((prev, curr) => (prev.lowDiff < curr.lowDiff ? prev : curr));

    return lowest.ruleId;
});

const generateCustomRule = (from: number, to: number): number[][] => {
    const diff = to - from;
    const numberOfIntervals = diff <= 10 ? ((diff === 0) ? 1: diff) : 10;
    const step = diff / numberOfIntervals;
    const output: number[][] = [];

    

    let newFrom = 0;
    let newTo = 0;

    for (let i = 1; i <= numberOfIntervals; i++) {
        if (i === 1) {
            newFrom = from;
        } else if(step === 1) {
            newFrom += 1;
        } else {
            newFrom += Math.floor(step) + 1
        }


        if(step === 1) {
            newTo = newFrom;
        } else if (i === numberOfIntervals) {
            newTo = to;
        } else {
            newTo =  Math.floor(newFrom + step) <= to ?  Math.floor(newFrom + step): to;
        }

        if((newFrom || newTo) > to ) {
            break;
        }

        output.push([newFrom,newTo])
    }

    return output;
}

const setRuleSet = ((sliderRange: number[]) => {
    const ruleCandidates: RuleCandidate[] = [];
    const DIFF_CUSTOM_RULE = 150;
    const MIN_VALUE_CUSTOM_RULE = 10;
    const SLIDER_DIFF = sliderRange[TO] - sliderRange[FROM];

    if ( 
            (sliderRange[FROM] > MIN_VALUE_CUSTOM_RULE && sliderRange[TO] < 100) || 
            (( SLIDER_DIFF < DIFF_CUSTOM_RULE) && sliderRange[FROM] > MIN_VALUE_CUSTOM_RULE)  ||
            (SLIDER_DIFF < 15 && SLIDER_DIFF !< 0)
        ) {
            rules.push(generateCustomRule(sliderRange[FROM], sliderRange[TO]));
    }

    for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        if (sliderRange[FROM] >= rule[0][FROM] && sliderRange[TO] <= rule[rule.length - 1][TO]) {
            //            SELECTED_RULESET = i;
            ruleCandidates.push(
                {
                    ruleId: i,
                    low: sliderRange[FROM],
                    lowDiff: sliderRange[FROM] - rule[0][FROM],
                    lowRule: rule[0],
                    high: sliderRange[TO],
                    highDiff: sliderRange[FROM] - rule[rule.length - 1][TO],
                    highRule: rule[rule.length - 1]
                }
            )
        }
    }

    SELECTED_RULESET = chooseCandidate(ruleCandidates);
})

export function getLabels(fetchedData: any, range: number[] = [0, 99999]) {
    setRuleSet(range);
    return rules[SELECTED_RULESET].map(d => {
        if (d[0] === d[1]) {
            return `${d[0]}`;
        }
        if (d[0] > 500) {
            return '500+';
        }
        return `${d[0]}-${d[1]}`

    })
}

export function prepareData(dataSet: { [key: number]: number; }, sliderRange: number[]): number[] {
    const output = [...new Array(getLabels(dataSet, sliderRange).length)].map(x => 0);
    setRuleSet(sliderRange);

    if (!dataSet) {
        return output;
    }
    let maxValue = 0;

    Object.entries(dataSet).forEach(([key, value]) => {
        const keyAsNum = parseInt(key, 10);

        if (keyAsNum > maxValue) {
            maxValue = keyAsNum;
        }

        if (isNaN(keyAsNum)) {
            return;
        }

        rules[SELECTED_RULESET].forEach((rule, index) => {
            if (keyAsNum >= rule[FROM] && keyAsNum <= rule[TO]) {
                output[index] += value;
                return;
            }
        })
    });

    return output;
}

import React, { useContext } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BusinessTypes } from "../BusinessTypes.enum";
import { ChartColor } from "./ChartColor";
import { Grid, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio, TextField } from "@material-ui/core"
import { LegendClick } from "../interface/Legend";
import { getLabels, prepareData } from "../../util/BarDistribution";
import { CurrentInfoContext } from "./CurrentInfoAggregatedContext";
import { Autocomplete } from "@material-ui/lab";
import { kommuner } from "../data/kommuner";
import BarDataLimiter from "./BarDataLimiter";
import { createMarks, defaultMarks, sliderRangeToValues, StepMark } from "../interface/StepMark";
import { Kommune } from "../data/Kommune.interface";

enum RadioValues {
    ALL = 'all',
    CITY = 'city'
}

const legendStyle = {
    right: 0
}

const BarReReChart: React.FC = () => {

    const [preparedDataSet, setDataSet] = React.useState<any[]>();
    const [visibleData, setVisibleData] = React.useState<any>({
        'all': true,
        '62.010': true,
        '62.020': true,
        '62.090': true
    });
    const { currentCity, setCurrentCity, dataSet, isLoading } = useContext(CurrentInfoContext);

    const [sliderRange, setSliderRange] = React.useState<number[]>([0, 0])
    const [maxValueRange, setMaxValueRange] = React.useState<number>(0);
    const [generatedMarks, setGeneratedMarks] = React.useState<StepMark[]>(defaultMarks.slice());
    const [radioValue, setRadioValue] = React.useState<RadioValues>(currentCity === 'all' ? RadioValues.ALL : RadioValues.CITY)
    const [searchCity, setSearchCity] = React.useState<string>(currentCity)

    const updateDatasets = () => {
        const all = prepareData(dataSet?.[BusinessTypes.All].mappedEmployees, sliderRangeToValues(sliderRange, generatedMarks));
        const konsulentVirksomhet = prepareData(dataSet?.[BusinessTypes.Konsulentvirksomhet].mappedEmployees, sliderRangeToValues(sliderRange, generatedMarks));
        const programmeringstjenester = prepareData(dataSet?.[BusinessTypes.Programmeringstjenester].mappedEmployees ?? {}, sliderRangeToValues(sliderRange, generatedMarks));
        const andreTjenester = prepareData(dataSet?.[BusinessTypes.AndreTjenester].mappedEmployees ?? {}, sliderRangeToValues(sliderRange, generatedMarks));
        const createdMarks = createMarks(dataSet);

        setGeneratedMarks(createMarks(dataSet));
        setMaxValueRange(Math.max(...createdMarks.map(d => d.key)));

        const output: any[] = [];
        getLabels(all, sliderRangeToValues(sliderRange, generatedMarks)).forEach((d, index) => {
            output.push({
                name: d,
                '62.010': programmeringstjenester[index],
                '62.020': konsulentVirksomhet[index],
                '62.090': andreTjenester[index],
                'all': all[index],
            })
        })

        return output;
    }

    const legendClick = (e: LegendClick) => {
        setVisibleData((prevState: any) => {
            return { ...prevState, [e.dataKey]: !prevState[e.dataKey] }
        })
    }

    const formatLegend = ((value: any, entry: any) => {
        const { color } = entry;
        let textDecoration = 'none';

        if (!visibleData[value]) {
            textDecoration = 'line-through';
        }
        return <span style={{ color: color, cursor: 'pointer', textDecoration: textDecoration }}>{value}</span>;
    });

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRadioValue: RadioValues = (event.target as HTMLInputElement).value as RadioValues;
        setRadioValue(newRadioValue);
        if (newRadioValue === RadioValues.ALL) {
            setCurrentCity('all');
        }
    }

    const autoCompleteSelected = ((event: any) => {
        setRadioValue(RadioValues.CITY);
    })

    const getChartTitle = () => {
        if (isLoading) {
            return "Laster inn data";
        } else if (currentCity === 'all') {
            return "Viser for alle";
        } else {
            return `Viser ${searchCity}`
        }
    }


    React.useEffect(() => {
        setDataSet(updateDatasets());
        // eslint-disable-next-line 
    }, [dataSet])

    React.useEffect(() => {
        setCurrentCity(searchCity)
    }, [searchCity, setCurrentCity])

    React.useMemo(() => {
        setSliderRange([0, maxValueRange])
    }, [maxValueRange])

    React.useEffect(() => {
        const updateData = setTimeout(() => setDataSet(updateDatasets()), 300);
        return () => clearTimeout(updateData);
        // eslint-disable-next-line         
    }, [sliderRange])

    React.useEffect(() => {
        if (radioValue === RadioValues.CITY) {
            setCurrentCity(searchCity);
        }
        // eslint-disable-next-line         
    }, [radioValue])

    return (
        <Grid container>
            <Grid item xs={12}>
                <h3>Antall ansatte i bedrifter</h3>                
            </Grid>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Velg område:</FormLabel>
                    <RadioGroup aria-label="target" row name="target1" value={radioValue} onChange={handleRadioChange}>
                        <FormControlLabel value="all" control={<Radio />} label="Alle" />
                        <FormControlLabel value="city" control={<Radio />} label="Kommune" />
                    </RadioGroup>
                </FormControl>
            <Autocomplete
                id="city-search"
                options={kommuner.sort((a: Kommune, b: Kommune) => a.name.localeCompare(b.name))}
                getOptionLabel={(option: Kommune) => option.name}
                style={{ width: 300 }}
                onChange={(event: any, newValue: Kommune | null, reason: string) => {
                    if (reason === ('clear' || 'remove-option')) {
                        setSearchCity('all');
                    } else if (newValue) {
                        setSearchCity(newValue.name);
                    } else {
                        setCurrentCity('all');
                    }
                }}
                renderInput={(params) => <TextField {...params} label="Kommunesøk" variant="outlined" />}
                disabledItemsFocusable={radioValue === RadioValues.ALL}
                selectOnFocus
                onFocus={(event) => { autoCompleteSelected(event) }}
            />
            <Grid item xs={12}>
                <h3>{getChartTitle()}</h3>
                <ResponsiveContainer width={isLoading ? '0%' : '100%'} height={600}>
                    <BarChart data={preparedDataSet}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" label={{ value: "Antall ansatte", offset: -7, position: "bottom" }} />
                        <YAxis label={{ value: "Antall bedrifter", angle: 270, position: "insideLeft", offset: -10 }} />
                        <Tooltip cursor={{ fill: "rgba(100,100,100,0.1)" }} />
                        <Legend wrapperStyle={legendStyle} onClick={legendClick} formatter={formatLegend} layout="vertical" align="right" verticalAlign="middle" />
                        <Bar hide={!visibleData["all"]} dataKey="all" fill={ChartColor.alle} />
                        <Bar hide={!visibleData["62.010"]} dataKey="62.010" fill={ChartColor.programmeringstjenester} />
                        <Bar hide={!visibleData["62.020"]} dataKey="62.020" fill={ChartColor.konsulentvirksomhet} />
                        <Bar hide={!visibleData["62.090"]} dataKey="62.090" fill={ChartColor.andreTjenester} />
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
            <Grid item xs={12}>
                <BarDataLimiter
                    generatedMarks={generatedMarks}
                    sliderRange={sliderRange}
                    setSliderRange={setSliderRange}
                    maxValueRange={maxValueRange}
                />
            </Grid>
        </Grid>
    );
}

export default BarReReChart;

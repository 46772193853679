import React, { useEffect } from "react";
import { useState } from "react";
import { AggregatedData } from "../Aggregated.interface";
import { BusinessTypes } from "../BusinessTypes.enum";
import LeadsOrgService from "../LeadsOrgService";

interface CurrentInfoInterface {
    setCurrentCity: (city: string) => void,
    currentCity: string,
    dataSet: AggregatedData,
    isLoading: boolean
    orgList: any
}

const defaultData: AggregatedData = {
    [BusinessTypes.All]: {mappedEmployees: [], numBusinesses: 0, numEmployees: 0},
    [BusinessTypes.Konsulentvirksomhet]: {mappedEmployees: [], numBusinesses: 0, numEmployees: 0},
    [BusinessTypes.Programmeringstjenester]: {mappedEmployees: [], numBusinesses: 0, numEmployees: 0},
    [BusinessTypes.AndreTjenester]: {mappedEmployees: [], numBusinesses: 0, numEmployees: 0}

}

export const CurrentInfoContext = React.createContext<CurrentInfoInterface>({setCurrentCity: () => {}, currentCity: 'all', dataSet: defaultData, isLoading: false, orgList: null});


export function CurrentInfoProvider({ children }: any) {
    const [data, setData] = useState<AggregatedData>(defaultData);
    const [currentCity, setCurrentCity] = useState('all');
    const [orgList, setOrgList] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const retrieveAggregatedData = (city: string) => {
        setIsLoading(true);
        if (city === 'all') {
            LeadsOrgService.getAggregatedAll()
                .then((response: any) => {
                    setData(response.data)
                })
                .catch((e: any) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                });
        } else {
            LeadsOrgService.getAggregatedCity(city)
                .then((response: any) => {
                    setData(response.data)
                })
                .catch((e: any) => {
                    console.error(e);
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const retrieveBusinessData = () => {
        
        LeadsOrgService.getAll()
            .then((response: any) => {
                setOrgList(response.data);
            })
            .catch((e: any) => {
                console.error(e);
            });
    };

    useEffect(() => {
        retrieveAggregatedData(currentCity);
    }, [currentCity]);

    useEffect(() => {
        retrieveBusinessData();
    }, [])
    

    return (
        <CurrentInfoContext.Provider value={{setCurrentCity, currentCity, dataSet: data, isLoading, orgList}}>
            {children}
        </CurrentInfoContext.Provider>
    )
}
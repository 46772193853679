import http from "../../api/http-leads";

const getAll = () => {
  return http.get("/enheter/currentinfo");
};

const getAggregatedAll = () => {
  return http.get("/enheter/currentinfo/aggregated");
};

const getAggregatedCity = (city: string) => {
  return http.get(`/enheter/currentinfo/aggregated?city=${city}`);
};

const getAggregatedAllHistorical = () => {
  return http.get(`/enheter/aggregated/monthly`);
};

const getEmployeeTrend = () => {
  return http.get(`/enheter/aggregated/trend`);
};

const get = (id: string) => {
  return http.get(`/enheter/org/${id}`);
};

const obj = {
  getAll,
  get,
  getAggregatedAll,
  getAggregatedCity,
  getAggregatedAllHistorical,
  getEmployeeTrend,
};

export default obj;

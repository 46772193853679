import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Alert, Color } from "@material-ui/lab";

import { useGoogleAuth } from "./GoogleSignIn";
import "./googleLogin.css";
import { useHistory } from "react-router-dom";

const Login: React.FC = () => {
  const [alert, setAlert] = useState<Color>("success");
  const { signIn, isSignedIn } = useGoogleAuth();
  const history = useHistory();

  useEffect(() => {
    if (isSignedIn) {
      return;
    }
  });
  return (
    <>
      <Helmet>
        <style>{"body { background-color: #144050; }"}</style>
      </Helmet>
      <div className="container_login">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-70 -28 650 650"
          xmlSpace="preserve"
          height="200px"
        >
          <path
            vectorEffect="non-scaling-stroke"
            d="M251 0 0 145v289l251 145 250-145V241l-83 48v97l-167 96-167-96V193l167-97 83 49-167 96v97l84 48v-97l250-144L251 0"
            fill="transparent"
            stroke="#40a67b"
            stroke-width="2"
          />
        </svg>
        <h1 style={{ textShadow: "4px 4px 5px grey", margin: "auto" }}>
          INNLOGGING FOR ELATE-ANSATTE
        </h1>
        <button
          className="button_style"
          onClick={async () => {
            const user = await signIn({ prompt: "select_account" });
            if (
              typeof user != undefined &&
              user?.profileObj?.email?.endsWith("elate.no")
            ) {
              history.push("/home");
            } else {
              setAlert("error");
              setTimeout(() => setAlert("success"), 10000);
            }
          }}
        >
          Sign In
        </button>
        {alert !== "success" && (
          <Alert className="login_alert" severity={alert} variant="filled">
            Enten feil passord/brukernavn eller ikke elatemail
          </Alert>
        )}
      </div>
    </>
  );
};

export default Login;

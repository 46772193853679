import React from "react";

const style = {
    fontSize: '75%' 
}

const BusinessCodePopup: React.FC = () => {
    return (
        <>
            <div style={style} className="info">
                <h2>62.010 - Programmeringstjenester</h2>
                <h3>Inkluderer:</h3>
                <p>Omfatter programutvikling, -endring, -test og -support med henblikk på å oppfylle en bestemt kundes behov: kundespesifisert programvare</p>
                <h3>Ekskluderer:</h3>
                <p>Utgivelse av programpakker grupperes under: 58.29 Utgivelse av annen programvare. Oversetting eller tilpassing av ferdigprodusert programvare til et bestemt marked for egen regning grupperes under: 58.29 Utgivelse av annen programvare. Planlegging og design av datasystemer som integrerer maskinvare-, programvare- og kommunikasjonsteknologi, uansett om levering av programvare er et ledd i produktet eller ikke, grupperes under: 62.02 Konsulentvirksomhet tilknyttet informasjonsteknologi</p>
            </div>
            <div style={style} className="info">
                <h2>62.020 - Konsulentvirksomhet</h2>
                <h3>Inkluderer:</h3>
                <p>Omfatter programutvikling, -endring, -test og -support med henblikk på å oppfylle en bestemt kundes behov: kundespesifisert programvare</p>
                <h3>Ekskluderer:</h3>
                <p>Utgivelse av programpakker grupperes under: 58.29 Utgivelse av annen programvare. Oversetting eller tilpassing av ferdigprodusert programvare til et bestemt marked for egen regning grupperes under: 58.29 Utgivelse av annen programvare. Planlegging og design av datasystemer som integrerer maskinvare-, programvare- og kommunikasjonsteknologi, uansett om levering av programvare er et ledd i produktet eller ikke, grupperes under: 62.02 Konsulentvirksomhet tilknyttet informasjonsteknologi</p>
            </div>
            <div style={style} className="info">
                <h2>62.090 - Andre tjenester</h2>
                <h3>Inkluderer:</h3>
                <p>Omfatter annen it- og datarelatert virksomhet som ikke er gruppert andre steder: rekonstruksjon av data ved systembrudd samt installasjon av programvare og maskinvare</p>
                <h3>Ekskluderer:</h3>
                <p>Installasjon av prosessorenhet og lignende enheter grupperes under: 33.20 Installasjon av industrimaskiner og utstyr. Programmeringstjenester grupperes under: 62.01 Programmeringstjenester. Konsulenttjenester tilknyttet datateknologi grupperes under: 62.02 Konsulenttjenester tilknyttet informasjonsteknologi. Forvaltning og drift av IT-systemer grupperes under: 62.03 Forvaltning og drift av IT-systemer. Databehandling og datalagring grupperes under 63.11 Databehandling, datalagring og tilknyttede tjenester</p>
            </div>
        </>
    )
}

export default BusinessCodePopup;

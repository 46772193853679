import React, { useEffect, useState } from "react";
import { Route, RouteProps } from "react-router-dom";
import { useGoogleAuth } from "./GoogleSignIn";
import { useHistory } from 'react-router-dom'

interface PrivateRouteProps extends Omit<RouteProps, "component"> {
    component: React.ElementType;
}
declare const window: any
const GuardedRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const {isSignedIn } = useGoogleAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const test = async (counter = 0) => {
      if (isSignedIn || counter >= 10) {
        setIsLoading(false)
        return;
      }
      
      if (!window.gapi || !(await window.gapi.auth2?.getAuthInstance())?.isSignedIn.get()) {
        setTimeout(() => test(counter + 1), 100);
      }
    }
    test();
  }, [isSignedIn]);

  if (isLoading) {
    return <></>
  }
  if (!isSignedIn && window.location.pathname.startsWith(`/loggedIn`)) {
    history.push("/")
    return null
  }
  if (!isSignedIn) {
      console.log('Logging in, we are not logged in, so we redirect to login')
      history.push('/login')
      return <></>;
  }
  return (
    <Route
      {...rest}
//        render={props => (!isSignedIn ? <Component {...props} /> : <Login />)}
      render={props => <Component {...props} />}
    />
  );
}

export default GuardedRoute;

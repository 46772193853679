import React from 'react';
import { AppBar, Toolbar, Typography, ThemeProvider } from "@material-ui/core"
import './App.css';
import {useStyles} from "./components/sidebar/styles";
import Theme from "./theme";
import Login from "./components/login/Login";
import { GoogleAuthProvider } from "./components/login/GoogleSignIn";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './components/home/Home';
import { LoggedIn } from './components/logged-in/LoggedIn';
import GuardedRoute from './components/login/GuardedRoute';
import Sidebar from './components/sidebar/Sidebar';
import Account from './components/account/Account';
import Assignments from './components/assignments/Assignments';
import Tripletex from './components/tripletex/Tripletex';
import Leads from './components/leads/Leads';

function App() {
  const classes = useStyles()
  return (
    <GoogleAuthProvider>
      <ThemeProvider theme={Theme}>
        <AppBar>
          <Toolbar>
            <Typography>
            Elate Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Router>
          <Sidebar>
            <Switch>
              <GuardedRoute path="/assignments/" component={Assignments} />              
              <GuardedRoute path="/account/" component={Account} />
              <GuardedRoute path="/logged-in/" component={LoggedIn} />
              <GuardedRoute path="/tripletex/" component={Tripletex} />
              <GuardedRoute path="/leads/:id/:orgId?" component={Leads} />              
              <GuardedRoute path="/leads/" component={Leads} />              
              <Route path="/login" component={Login} />              
              <GuardedRoute path="/" component={Home} />
            </Switch>
          </Sidebar>
        </Router>        
        <main className={classes.content}>
          <div className={classes.toolbar} />
          
        </main>
      </ThemeProvider>
    </GoogleAuthProvider>
  );
}

export default App;

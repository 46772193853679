import React from "react"

interface Props {
  result: Array<any>
}

const ResultText: React.FC<Props> = ({ result }) => {
  const resultString: string = JSON.stringify(result)
  const initText = () => {
    return <p>The results will be shown here</p>
  }
  const responseText = (resultString: string, results: any[]) => {
    return (
      <div>
        <p>Number of results: {result.length}</p>
        <p>
          {resultString === "[]"
            ? "No data matches your query"
            : result.map(elem => {
                return <pre>{JSON.stringify(elem, null, 2)}</pre>
              })}
        </p>
      </div>
    )
  }
  return (
    <div>
      {resultString === '["initial"]'
        ? initText()
        : responseText(resultString, result)}
    </div>
  )
}

export default ResultText

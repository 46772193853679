import React, { useContext, useEffect } from "react";
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';

import "./employee-distribution.css";
import { Grid } from "@material-ui/core";
import { CurrentInfoContext } from "./rechart/CurrentInfoAggregatedContext";

const OrgList: React.FC = () => {
    const { orgList } = useContext(CurrentInfoContext);
    const history = useHistory();
    const [state, setState] = React.useState({
        columns: [
          { title: 'Organisasjonsnummer', field: 'organisasjonsnummer' },
          { title: 'Name', field: 'name' },
          { title: 'Ant. ansatte', field: 'numberOfEmployees' },
          { title: 'N.kode', field: 'businessCode' }
        ],
        data: orgList?.slice(),
      });

      useEffect(() => {
        setState({
            columns: [
              { title: 'Organisasjonsnummer', field: 'organisasjonsnummer' },
              { title: 'Name', field: 'name' },
              { title: 'Ant. ansatte', field: 'numberOfEmployees' },
              { title: 'N.kode', field: 'businessCode' }
            ],
            data: orgList?.slice(),
          })
      }, [orgList])

    const rowClick = (rowData: any) => {
        history.push(`/leads/org/${rowData.organisasjonsnummer}`)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
            <MaterialTable
                onRowClick={(event, rowData) => rowClick(rowData)}
                title="Søk organisasjoner"
                columns={state.columns}
                data={state.data}
                options={{sorting: true, pageSize: 10, tableLayout: "fixed" }}
            />
            </Grid>

        </Grid>
    )
}

export default OrgList;

import React from "react"
import Chart from "react-google-charts"

type chartProps = {
  chart: [number[] | string[]]
}

const SavingsChart: React.FC<chartProps> = ({ chart }) => {
  return (
    <div className="tripletex_div flex-1 border_double">
      <Chart
        width={"600px"}
        height={"400px"}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={chart}
        options={{
          hAxis: {
            title: "Months",
          },
          vAxis: {
            title: "Savings",
            viewWindow: { min: 0, max: 85000 },
          },
        }}
        rootProps={{ "data-testid": "1" }}
      />
    </div>
  )
}

export default SavingsChart

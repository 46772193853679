export const ChartColor = {
    alle: '#675f5f',
    alleInverted: '#675f61',
    konsulentvirksomhet: '#19990c',
    konsulentvirksomhetInverted: '#47990b',
    programmeringstjenester: '#927210',
    programmeringstjenesterInverted: '#924710',
    andreTjenester: '#323dd7',
    andreTjenesterInverted: '#3273d6',
    negativeValue: 'red',
    positiveValue: 'green',
    neutralValue: 'black'
}

import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react"
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { MonthToString } from "../../../common/MonthToString";
import ChartWidth from "../../util/ChartWidth";
import { ChartColor } from "./ChartColor";
import { LegendClick } from "../interface/Legend";
import LeadsOrgService from "../LeadsOrgService";


/* Slik ser dataen ut
[{
    numBusinesses: 12631
    numEmployees: 31048
    registeredMonth: "2019-10-01 00:00:00.0"
    typeBusiness: "62.010"
}]
*/

/* Slik skal den formateres for D3: 

[{
    name:
    typebusiness_numBusinesses:
    typebusiness_numEmployees:
    typebusiness_numBusinesses:
    typebusiness_numEmployees:
    typebusiness_numBusinesses:
    typebusiness_numEmployees:
    typebusiness_numBusinesses:
    typebusiness_numEmployees:
}]

*/

const legendStyle = {
    right: 0
}

const MonthlyReAggregated: React.FC = () => {
    const STROKEWIDTH = 2;
    const STROKEWIDTH_HOVER = 3;
    const getChartWidth = ChartWidth();
    const [chartWidth, setChartWidth] = useState<number>(getChartWidth);

    const [aggregatedMonthly, setAggregatedMonthly] = useState<any | undefined>([]);
    const [lineStatus, setLineStatus] = useState<any>({
        "62.010-business": { visible: true, strokeWidth: STROKEWIDTH },
        "62.010-employees": { visible: true, strokeWidth: STROKEWIDTH },
        "62.020-business": { visible: false, strokeWidth: STROKEWIDTH },
        "62.020-employees": { visible: true, strokeWidth: STROKEWIDTH },
        "62.090-business": { visible: true, strokeWidth: STROKEWIDTH },
        "62.090-employees": { visible: true, strokeWidth: STROKEWIDTH },
        "all-business": { visible: false, strokeWidth: STROKEWIDTH },
        "all-employees": { visible: true, strokeWidth: STROKEWIDTH },
    });

    useEffect(() => {
        retrieveOrg();
        // eslint-disable-next-line         
    }, []);

    useEffect(() => {
        setChartWidth(getChartWidth)
    }, [getChartWidth])

    const formatDateLabel = (date: Date): string => {
        return MonthToString(date.getMonth()) + ' - ' + date.getFullYear().toString().substring(2)
    };

    const retrieveOrg = () => {
        LeadsOrgService.getAggregatedAllHistorical()
            .then((response: any) => {
                const output: any[] = [];
                response.data.forEach((data: any) => {
                    let inserted = false;
                    output.forEach((out, index) => {
                        if (out.name === formatDateLabel(new Date(data.registeredMonth))) {
                            output[index][data.typeBusiness + '-business'] = data.numBusinesses;
                            output[index][data.typeBusiness + '-employees'] = data.numEmployees;
                            inserted = true;
                            return;
                        }
                    })
                    if (!inserted) {
                        output.push({
                            name: formatDateLabel(new Date(data.registeredMonth)),
                            [data.typeBusiness + '-business']: data.numBusinesses,
                            [data.typeBusiness + '-employees']: data.numEmployees
                        })
                    }
                })
                setAggregatedMonthly(output);
            })
            .catch((e: any) => {
                console.error(e);
            });
    };

    const legendClick = (e: LegendClick) => {
        setLineStatus((prevState: any) => {
            return { ...prevState, [e.dataKey]: { visible: !prevState[e.dataKey].visible, strokeWidth: STROKEWIDTH } }
        })
    }

    const formatLegend = ((value: any, entry: any) => {
        const { color } = entry;
        let textDecoration = 'none';

        if (!lineStatus[entry.dataKey].visible) {
            textDecoration = 'line-through';
        }
        return <span style={{ color: color, cursor: 'pointer', textDecoration: textDecoration }}>{value}</span>;
    });

    const mouseOverLegend = ((event: LegendClick) => {
        setLineStatus((prevState: any) => { return { ...prevState, [event.dataKey]: { strokeWidth: STROKEWIDTH_HOVER, visible: prevState[event.dataKey].visible } } });

    });

    const mouseLeaveLegend = ((event: LegendClick) => {
        setLineStatus((prevState: any) => { return { ...prevState, [event.dataKey]: { strokeWidth: STROKEWIDTH, visible: prevState[event.dataKey].visible } } });
    });

    return (
        <Grid item xs={12}>
            <h3>Totale antall ansatte og bedrifter</h3>
            <LineChart data={aggregatedMonthly}
                width={chartWidth}
                height={600}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend wrapperStyle={legendStyle} onClick={legendClick} onMouseEnter={mouseOverLegend} onMouseLeave={mouseLeaveLegend} formatter={formatLegend} layout="vertical" align="right" verticalAlign="middle" />
                <Line type="monotone" strokeWidth={lineStatus["62.010-employees"].strokeWidth} name="62.010 - Ansatte" hide={!lineStatus["62.010-employees"].visible} dataKey="62.010-employees" stroke={ChartColor.programmeringstjenesterInverted} />
                <Line type="monotone" strokeWidth={lineStatus["62.020-employees"].strokeWidth} name="62.020 - Ansatte" hide={!lineStatus["62.020-employees"].visible} dataKey="62.020-employees" stroke={ChartColor.konsulentvirksomhetInverted} />
                <Line type="monotone" strokeWidth={lineStatus["62.090-employees"].strokeWidth} name="62.090 - Ansatte" hide={!lineStatus["62.090-employees"].visible} dataKey="62.090-employees" stroke={ChartColor.andreTjenesterInverted} />
                <Line type="monotone" strokeWidth={lineStatus["all-employees"].strokeWidth} name="Alle - Ansatte" hide={!lineStatus["all-employees"].visible} dataKey="all-employees" stroke={ChartColor.alleInverted} />
                <Line type="monotone" strokeWidth={lineStatus["62.010-business"].strokeWidth} name="62.010 - Bedrifter" hide={!lineStatus["62.010-business"].visible} dataKey="62.010-business" stroke={ChartColor.programmeringstjenester} />
                <Line type="monotone" strokeWidth={lineStatus["62.020-business"].strokeWidth} name="62.020 - Bedrifter" hide={!lineStatus["62.020-business"].visible} dataKey="62.020-business" stroke={ChartColor.konsulentvirksomhet} />
                <Line type="monotone" strokeWidth={lineStatus["62.090-business"].strokeWidth} name="62.090 - Bedrifter" hide={!lineStatus["62.090-business"].visible} dataKey="62.090-business" stroke={ChartColor.andreTjenester} />
                <Line type="monotone" strokeWidth={lineStatus["all-business"].strokeWidth} name="Alle - Bedrifter" hide={!lineStatus["all-business"].visible} dataKey="all-business" stroke={ChartColor.alle} />
            </LineChart>
        </Grid>
    )

}

export default MonthlyReAggregated;

import React from "react"

const Logo = require("../../assets/Spinner1.gif")

const Spinner: React.FC = () => {
  return (
    <div className="container_login">
      <img src={Logo} alt="Loading..." style={{ margin: "auto" }} />
    </div>
  )
}

export default Spinner

import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useGoogleAuth } from "../login/GoogleSignIn";

const ElateLogo = require("../../assets/ElateLogo.png")

const Account: React.FC<RouteComponentProps> = () => {
  const { googleUser } = useGoogleAuth()
  return (
    <div className="container_loggedIn">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <img
          style={{
            height: "140px",
            width: "140px",
            borderRadius: "140px",
            margin: "auto",
            marginBottom: "25px",
            padding: "40px",
            borderStyle: "double",
          }}
          src={googleUser?.profileObj?.imageUrl}
          alt={ElateLogo}
        />
        <h4 style={{ margin: "auto", padding: "5px" }}>
          {googleUser?.profileObj?.name}
        </h4>
        <h4 style={{ margin: "auto", padding: "5px", paddingBottom: "15px" }}>
          {googleUser?.profileObj?.email}
        </h4>
      </div>
    </div>
  )
}

export default Account

import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react"
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { MonthToString } from "../../../common/MonthToString";
import ChartWidth from "../../util/ChartWidth";
import { ChartColor } from "./ChartColor";
import { LegendClick } from "../interface/Legend";
import LeadsOrgService from "../LeadsOrgService";

const legendStyle = {
    right: 0
}

const EmployeeReTrend: React.FC = () => {

    const STROKEWIDTH = 2;
    const STROKEWIDTH_HOVER = 3;
    const getChartWidth = ChartWidth();
    const [aggregatedMonthly, setAggregatedMonthly] = useState<any | undefined>([]);
    const [chartWidth, setChartWidth] = useState<number>(getChartWidth);
    const [lineStatus, setLineStatus] = useState<any>({
        "62.010-quit_business": { visible: true, strokeWidth: STROKEWIDTH },
        "62.010-started_business": { visible: true, strokeWidth: STROKEWIDTH },
        "62.010-trend_change": { visible: true, strokeWidth: STROKEWIDTH },
        "62.020-quit_business": { visible: false, strokeWidth: STROKEWIDTH },
        "62.020-started_business": { visible: false, strokeWidth: STROKEWIDTH },
        "62.020-trend_change": { visible: false, strokeWidth: STROKEWIDTH },
        "62.090-quit_business": { visible: false, strokeWidth: STROKEWIDTH },
        "62.090-started_business": { visible: false, strokeWidth: STROKEWIDTH },
        "62.090-trend_change": { visible: false, strokeWidth: STROKEWIDTH },
        "all-quit_business": { visible: true, strokeWidth: STROKEWIDTH },
        "all-started_business": { visible: true, strokeWidth: STROKEWIDTH },
        "all-trend_change": { visible: true, strokeWidth: STROKEWIDTH }
    });

    useEffect(() => {
        retrieveOrg();
        // eslint-disable-next-line                 
    }, []);

    useEffect(() => {
        setChartWidth(getChartWidth)
    }, [getChartWidth])

    const formatDateLabel = (date: Date): string => {
        return MonthToString(date.getMonth()) + ' - ' + date.getFullYear().toString().substring(2)
    };

    const retrieveOrg = () => {
        LeadsOrgService.getEmployeeTrend()
            .then((response: any) => {
                const output: any[] = [];
                response.data.forEach((data: any) => {
                    let inserted = false;
                    output.forEach((out, index) => {
                        if (out.name === formatDateLabel(new Date(data.month))) {
                            output[index][data.type_business + '-quit_business'] = data.quit_business;
                            output[index][data.type_business + '-started_business'] = data.started_business;
                            output[index][data.type_business + '-trend_change'] = data.trend_change;
                            inserted = true;
                            return;
                        }
                    })
                    if (!inserted) {
                        output.push({
                            name: formatDateLabel(new Date(data.month)),
                            [data.type_business + '-quit_business']: data.quit_business,
                            [data.type_business + '-started_business']: data.started_business,
                            [data.type_business + '-trend_change']: data.trend_change
                        })
                    }
                })
                setAggregatedMonthly(output);
            })
            .catch((e: any) => {
                console.error(e);
            });
    };

    const legendClick = (data: LegendClick) => {
        setLineStatus((prevState: any) => {
            return { ...prevState, [data.dataKey]: { visible: !prevState[data.dataKey].visible, strokeWidth: STROKEWIDTH } }
        })
    }

    const formatLegend = ((value: any, entry: any) => {
        const { color } = entry;
        let textDecoration = 'none';

        if (!lineStatus[entry.dataKey].visible) {
            textDecoration = 'line-through';
        }
        return <span style={{ color: color, cursor: 'pointer', textDecoration: textDecoration }}>{value}</span>;
    });

    const fillColor = (dataKey: string): string => {
        let color = ChartColor.neutralValue;
        switch (dataKey) {
            case "62.010-quit_business":
            case "62.020-quit_business":
            case "62.090-quit_business":
            case "all-quit_business":
                color = ChartColor.negativeValue;
                break;
            case "62.010-started_business":
            case "62.020-started_business":
            case "62.090-started_business":
            case "all-started_business":
                color = ChartColor.positiveValue;
                break;
            case "62.010-trend_change":
            case "62.020-trend_change":
            case "62.090-trend_change":
            case "all-trend_change":
                color = ChartColor.neutralValue;
                break;
            default:
        }
        return color;
    }

    const customLegend = (props: any) => {
        return (
            <ul style={{ listStyle: 'none' }}>
                {
                    props.payload.map((data: LegendClick) => {
                        const { color } = data;
                        let textDecoration = 'none';

                        if (!lineStatus[data.dataKey].visible) {
                            textDecoration = 'line-through';
                        }

                        return (
                            <li style={{ color: color, cursor: 'pointer', textDecoration: textDecoration }}
                                onClick={() => legendClick(data)} onMouseEnter={() => mouseOverLegend(data)}
                                onMouseLeave={() => mouseLeaveLegend(data)}
                                key={data.payload.dataKey}>
                                <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}><path strokeWidth="4" fill={fillColor(data.dataKey)} stroke={data.color} d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16" className="recharts-legend-icon"></path></svg>
                                {data.value}
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    const mouseOverLegend = ((data: LegendClick) => {
        setLineStatus((prevState: any) => { return { ...prevState, [data.dataKey]: { strokeWidth: STROKEWIDTH_HOVER, visible: prevState[data.dataKey].visible } } });

    });

    const mouseLeaveLegend = ((event: LegendClick) => {
        setLineStatus((prevState: any) => { return { ...prevState, [event.dataKey]: { strokeWidth: STROKEWIDTH, visible: prevState[event.dataKey].visible } } });
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <h3>Trender i IT-bransjen (Er det tilvekst, eller frafall i de ulike sektorene eller totalt)</h3>
                <LineChart data={aggregatedMonthly}
                    width={chartWidth}
                    height={600}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend content={customLegend} wrapperStyle={legendStyle} onClick={legendClick} onMouseEnter={mouseOverLegend} onMouseLeave={mouseLeaveLegend} formatter={formatLegend} layout="vertical" align="right" verticalAlign="middle" />
                    <Line type="monotone" strokeWidth={lineStatus["62.010-quit_business"].strokeWidth} name="62.010 - Sluttet" hide={!lineStatus["62.010-quit_business"].visible} dataKey="62.010-quit_business" fill={ChartColor.negativeValue} stroke={ChartColor.programmeringstjenester} />
                    <Line type="monotone" strokeWidth={lineStatus["62.010-started_business"].strokeWidth} name="62.010 - Startet" hide={!lineStatus["62.010-started_business"].visible} dataKey="62.010-started_business" fill={ChartColor.positiveValue} stroke={ChartColor.programmeringstjenester} />
                    <Line type="monotone" strokeWidth={lineStatus["62.010-trend_change"].strokeWidth} name="62.010 - Trend" hide={!lineStatus["62.010-trend_change"].visible} dataKey="62.010-trend_change" fill={ChartColor.neutralValue} stroke={ChartColor.programmeringstjenester} />
                    <Line type="monotone" strokeWidth={lineStatus["62.020-quit_business"].strokeWidth} name="62.020 - Sluttet" hide={!lineStatus["62.020-quit_business"].visible} dataKey="62.020-quit_business" fill={ChartColor.negativeValue} stroke={ChartColor.konsulentvirksomhet} />
                    <Line type="monotone" strokeWidth={lineStatus["62.020-started_business"].strokeWidth} name="62.020 - Startet" hide={!lineStatus["62.020-started_business"].visible} dataKey="62.020-started_business" fill={ChartColor.positiveValue} stroke={ChartColor.konsulentvirksomhet} />
                    <Line type="monotone" strokeWidth={lineStatus["62.020-trend_change"].strokeWidth} name="62.020 - Trend" hide={!lineStatus["62.020-trend_change"].visible} dataKey="62.020-trend_change" fill={ChartColor.neutralValue} stroke={ChartColor.konsulentvirksomhet} />
                    <Line type="monotone" strokeWidth={lineStatus["62.090-quit_business"].strokeWidth} name="62.090 - Sluttet" hide={!lineStatus["62.090-quit_business"].visible} dataKey="62.090-quit_business" fill={ChartColor.negativeValue} stroke={ChartColor.andreTjenester} />
                    <Line type="monotone" strokeWidth={lineStatus["62.090-started_business"].strokeWidth} name="62.090 - Startet" hide={!lineStatus["62.090-started_business"].visible} dataKey="62.090-started_business" fill={ChartColor.positiveValue} stroke={ChartColor.andreTjenester} />
                    <Line type="monotone" strokeWidth={lineStatus["62.090-trend_change"].strokeWidth} name="62.090 - Trend" hide={!lineStatus["62.090-trend_change"].visible} dataKey="62.090-trend_change" fill={ChartColor.neutralValue} stroke={ChartColor.andreTjenester} />
                    <Line type="monotone" strokeWidth={lineStatus["all-quit_business"].strokeWidth} name="Alle - Sluttet" hide={!lineStatus["all-quit_business"].visible} dataKey="all-quit_business" fill={ChartColor.negativeValue} stroke={ChartColor.alle} />
                    <Line type="monotone" strokeWidth={lineStatus["all-started_business"].strokeWidth} name="Alle - Startet" hide={!lineStatus["all-started_business"].visible} dataKey="all-started_business" fill={ChartColor.positiveValue} stroke={ChartColor.alle} />
                    <Line type="monotone" strokeWidth={lineStatus["all-trend_change"].strokeWidth} name="Alle - Trend" hide={!lineStatus["all-trend_change"].visible} dataKey="all-trend_change" fill={ChartColor.neutralValue} stroke={ChartColor.alle} />
                </LineChart>
            </Grid>
        </Grid>
    )

}

export default EmployeeReTrend

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LeadsOrgService from "./LeadsOrgService";
import { OrgResponse } from "./OrgResponse";
import MaterialTable from "material-table";
import { Button } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface OrgResponses {
    data: OrgResponse[]
}

interface Props {
    orgId: string
}

const OrgDetails: React.FC<Props> = (props) => {
    const urlId = props.orgId;
    const history = useHistory();
    const [currentOrgs, setCurrentOrg] = useState<any>();

    useEffect(() => {
        retrieveOrg(urlId ? urlId : '');
    }, [urlId]);

    const [state, setState] = React.useState({
        columns: [
            { field: 'navn', title: 'Navn' },
            { field: 'date', title: 'Dato' },
            { field: 'antallAnsatte', title: 'Antall Ansatte' },
            { field: 'forretningsAdresse_Adresse', title: 'Forretningsadresse' },
            { field: 'forretningsAdresse_Kommune', title: 'F.orretningskommune' },
            { field: 'maalform', title: 'Målform' },
            { field: 'organisasjonsnummer', title: 'Org.Nr' },
            { field: 'postadresse_Adresse', title: 'Postadresse' },
            { field: 'postadresse_Kommune', title: 'Postkommune' },
            { field: 'stiftelsesdato', title: 'Stiftelsesdato' },
            { field: 'registreringsdatoEnhetsregisteret', title: 'Dato registrert i Enhetsregisteret' },
            { field: 'registrertIForetaksregisteret', title: 'Registrert i Foretaksregisteret' },
            { field: 'konkurs', title: 'Konkurs' },
            { field: 'registrertIFrivillighetsregisteret', title: 'Registrert i Frivillighetsregisteret' },
            { field: 'registrertIStiftelsesregisteret', title: 'Registrert i Stiftelsesregisteret' },
            { field: 'registrertIMvaregisteret', title: 'Registrert I MVAregisteret' },
            { field: 'fjernet', title: 'Fjernet' },
            { field: 'underAvvikling', title: 'Under Avvikling' },
            { field: 'underTvangsavviklingEllerTvangsopplosning', title: 'Under tvangsavvikling eller tvangsoppløsning' },
        ],
        data: currentOrgs?.slice(),
    });

    const retrieveOrg = (id: string) => {
        LeadsOrgService.get(id)
            .then((response: OrgResponses) => {
                setCurrentOrg(response.data);
                setState(prevState => ({
                    ...prevState,
                    data: response.data
                }))
            })
            .catch((e: any) => {
                console.error(e);
            });
    };

    const renderDetails = () => {
        return (
            <>
                <Button onClick={() => { history.push(`/leads/org`) }} color="inherit"><ArrowBackIcon />Tilbake</Button>
                <div className="col-md-12">
                    <MaterialTable
                        title="Firmadetaljer"
                        columns={state.columns}
                        data={state.data}
                        options={{ sorting: true, pageSize: 10 }}
                    />
                </div>
            </>
        )
    }
    return (
        <div className="col-md-6">
            {renderDetails()}
        </div>
    )
}

export default OrgDetails

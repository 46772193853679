import React, { useState, useEffect, Fragment } from "react";
import { RouteComponentProps } from "react-router-dom";
import axios from "axios";

import Spinner from "../misc/Spinner";
import SavingsChart from "./SavingsChart";
import PersonalBudget from "./PersonalBudget";
import Expenses from "./Expenses";
import { useGoogleAuth } from "../login/GoogleSignIn"

const Tripletex: React.FC<RouteComponentProps> = () => {
  const { googleUser } = useGoogleAuth()

  const [loading, setLoading] = useState<boolean>(false)
  const [chart, setChart] = useState<[number[] | string[]]>([[]])
  const [personalBugdet, setPersonalBugdet] = useState<number>(0)
  const [expenses, setExpenses] = useState<
    { price: number; date: string; desc: string }[]
  >([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const [response1, response2] = await axios.all([
        axios.get("http://168.63.62.226:5000/money", {
          headers: { Authorization: googleUser?.tokenId },
        }),
        axios.get("http://168.63.62.226:5000/expenses", {
          headers: { Authorization: googleUser?.tokenId },
        }),
      ])
      setChart(response1.data.chart)
      setPersonalBugdet(response1.data.savings)
      setExpenses(response2.data)
      setLoading(false)
    }
    fetchData()
  }, [googleUser])
  return (
    <div style={{ padding: "24px" }}>
      <h1 style={{ margin: "auto", textAlign: "center" }}>Tripletex</h1>
      <div className="auto_margin container_row">
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <PersonalBudget personalBudget={personalBugdet} />
            <SavingsChart chart={chart} />
            <Expenses expenses={expenses} />
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default Tripletex

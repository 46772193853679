import React from "react"
import { Alert } from "@material-ui/lab"

type budgetProps = {
  personalBudget: number
}

const PersonalBudget: React.FC<budgetProps> = ({ personalBudget }) => {
  const status =
    personalBudget < 65000
      ? "info"
      : personalBudget === 70000
      ? "error"
      : "warning"
  return (
    <div className="flex-2 tripletex_div">
      <h2>Personlig pott: kr {personalBudget}.-</h2>
      {(() => {
        switch (status) {
          case "error":
            return (
              <Alert severity={status} variant="filled">
                Du har krysset maksgrensen
              </Alert>
            )
          case "warning":
            return (
              <Alert severity={status} variant="filled">
                Du nærmer deg maksgrensen
              </Alert>
            )
          case "info":
            return (
              <Alert severity={status} variant="filled">
                {/*Infoteksten stemmer kun om den ansatte har jobbet her 6 måender eller mer */}
                Du krysser maksgrensen om{" "}
                {Math.ceil((70000 - personalBudget) / 3250)} måender
              </Alert>
            )
          default:
            return null
        }
      })()}
    </div>
  )
}

export default PersonalBudget

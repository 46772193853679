import React from "react"
import clsx from "clsx"
import { RouteComponentProps, withRouter } from "react-router-dom"

import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import {
  AppBar,
  Toolbar,
  Drawer,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core"

import { Primary, Secondary } from "./Lists"
import { useStyles } from "./styles"
import ElateIcon from "./ElateIcon"
import Theme from "./theme"
import { useGoogleAuth } from "../login/GoogleSignIn"

const Sidebar: React.FC<RouteComponentProps> = ({
  children,
  history,
  location,
}) => {
  const classes = useStyles()
  const {isSignedIn } = useGoogleAuth();

  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const showSidebar = (): boolean =>{
    return isSignedIn;
  }

  if (!showSidebar()) {
    return (
      <>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <Typography variant="h6" noWrap>
              Elate Dashboard
            </Typography>
            <ElateIcon />
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </>
    )
  }

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Elate Dashboard
          </Typography>
          <ElateIcon />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {Theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <Primary history={history} />
        <Divider />
        <Secondary history={history} onClick={handleDrawerClose} />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}

export default withRouter(Sidebar)

import React from "react";

import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const useStyles = makeStyles({
  imageIcon: {
    height: "100%",
  },
  iconRoot: {
    textAlign: "center",
    marginLeft: "auto",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiIcon: {
      root: {
        width: "2em",
        height: "2em",
      },
    },
  },
});

const ElateIcon = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Icon classes={{ root: classes.iconRoot }}>
        <a
          href="https://web.elate.no"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-70 -28 650 650"
            xmlSpace="preserve"
          >
            <path
              vectorEffect="non-scaling-stroke"
              d="M251 0 0 145v289l251 145 250-145V241l-83 48v97l-167 96-167-96V193l167-97 83 49-167 96v97l84 48v-97l250-144L251 0"
              fill="transparent"
              stroke="#40a67b"
              strokeWidth="2"
            />
          </svg>
        </a>
      </Icon>
    </ThemeProvider>
  );
};

export default ElateIcon;

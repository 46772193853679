import React from "react";

type expensesProps = {
  expenses: { price: number; date: string; desc: string }[]
}

const Expenses: React.FC<expensesProps> = ({ expenses }) => {
  return (
    <div className="tripletex_div flex-3 border_double">
      <h2>5 siste utgifter</h2>
      {expenses.slice(0, 5).map(exp => (
        <p>
          Pris: {exp.price}kr, Dato: {exp.date}, Beskrivelse: {exp.desc}
        </p>
      ))}
    </div>
  )
}

export default Expenses

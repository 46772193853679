import React, { useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import { GoogleUser } from "react-use-googlelogin/dist/types";
import Axios from "axios";

import "./es-form.css";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";

import { useGoogleAuth } from "../login/GoogleSignIn";
import ResultText from "./ResultText";

const fetchExpress = async (
  title: string,
  skill: string,
  fromDate: string,
  toDate: string,
  googleUser: GoogleUser
) => {
  const response = await Axios.get(
    `http://168.63.62.226:5000/work?title=${title}&skill=${skill}&fromDate=${fromDate}&toDate=${toDate}`,
    { headers: { Authorization: googleUser?.tokenId } }
  )
  return response.data
}

interface formValues {
  title: string
  skills: string
  dateFrom: Date
  dateTo: Date
}

const EsForm: React.FC<{}> = () => {
  const [result, setResult] = useState<any[]>(["initial"])
  const [startDate, setStartDate] = useState(new Date("01/01/2020"))
  const [endDate, setEndDate] = useState(new Date())
  const { googleUser } = useGoogleAuth()

  if (googleUser === undefined) {
    return null
  }
  const handleChangeStartDate = (date: Date) => {
    setStartDate(date)
  }
  const handleChangeEndDate = (date: Date) => {
    setEndDate(date)
  }
  const formatDate = (date: Date) => {
    const dd = date.getDate()
    const day = dd < 10 ? `0${dd}` : dd
    const mm = date.getMonth() + 1
    const month = mm < 10 ? `0${mm}` : mm
    const year = date.getFullYear()
    return `${year}-${month}-${day}T00:00:00`
  }
  return (
    <div className="allForm">
      <div className="esForm">
        <div className="formContent">
          <h1>Elate querymachine</h1>
          <Formik
            initialValues={{
              title: "",
              skills: "",
              dateFrom: new Date(),
              dateTo: new Date(),
            }}
            onSubmit={(values: formValues, { setSubmitting }) => {
              setTimeout(async () => {
                const resp = await fetchExpress(
                  values.title,
                  values.skills,
                  formatDate(startDate),
                  formatDate(endDate),
                  googleUser
                )
                setSubmitting(false)
                setResult(resp)
              }, 400)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="formField">
                  <label>Title</label>
                  <Field name="title" />
                  <ErrorMessage name="title" component="div" />
                </div>
                <div className="formField">
                  <label>Skills</label>
                  <Field name="skills" />
                  <ErrorMessage name="title" component="div" />
                </div>
                <div className="formField">
                  <label>From date</label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleChangeStartDate}
                  />
                </div>
                <div className="formField">
                  <label>To date</label>
                  <DatePicker
                    selected={endDate}
                    onChange={handleChangeEndDate}
                  />
                </div>
                <div className="formField">
                  <button type="submit" disabled={isSubmitting} id="formButton">
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div className="textField">
        <ResultText result={result} />
      </div>
    </div>
  )
}

export default EsForm

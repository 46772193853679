import { useEffect, useState } from "react"
import UseWindowDimensions from "./UseWindowDimensions";

const ChartWidth = (): number => {
    const MENU_WIDTH = 125;
    const windowWidth = UseWindowDimensions().width;
    
    const [chartWidth, setChartWidth] = useState<number>(windowWidth - MENU_WIDTH);

    useEffect(() => {
        setChartWidth(windowWidth - MENU_WIDTH)
    }, [windowWidth])


    return chartWidth;
}

export default ChartWidth;
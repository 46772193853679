import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import InfoIcon from '@material-ui/icons/Info';

import "./leads.css";
import BarReReChart from "./rechart/BarReReChart";
import EmployeeReTrend from "./rechart/EmployeeReTrend";
import MonthlyReAggregated from "./rechart/MonthlyReAggregated";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Popover, withStyles } from "@material-ui/core";
import BusinessCodePopup from "./rechart/BusinessCodePopup";
import { CurrentInfoProvider } from "./rechart/CurrentInfoAggregatedContext";
import OrgList from "./OrgList";
import OrgDetails from "./OrgDetails";


interface MatchParams {
    id: string;
    orgId: string;
}

const popOverStyle = {
    left: '10px'
}

const StyledPopover = withStyles({
    paper: {
        display: 'inline-flex'
    }
})(Popover)

const infoButtonStyle = {
    marginLeft: 'auto',
    marginRight: '3em',
    backgroundColor: 'transparent',
    border: 'none',
    verticalAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    ':hover': {
        border: 'none'
    },
    ':focus': {
        border: 'none',
        outline: 'none',
    },
    ':active': {
        border: 'none'
    },
}

interface MatchProps extends RouteComponentProps<MatchParams> { }

const Leads: React.FC<MatchProps> = ({ match }) => {
    const [tabIndex, setTabIndex] = useState('1');
    const urlId = match.params.id;
    const history = useHistory();

    useEffect(() => {
        if (urlId) {
            setTabIndex(urlId);
        } else {
            setTabIndex('1');
        }
    }, [urlId]);

    const [anchorEl, setAnchorEl] = React.useState({
        '1': null
    });

    const handleInfoClick = (event: any, id: any) => {
        setAnchorEl((prevState: any) => { return { ...prevState, [id]: event.currentTarget } });
    };

    const handleInfoClose = () => {
        setAnchorEl(() => { return { '1': null } });
    };

    const handleTabChange = (event: any, newValue: string) => {
        history.push(`/leads/${newValue}`)
    };

    return (
        <div className="leads-container">
            <Grid container>
                <TabContext value={tabIndex}>
                    <AppBar position="static">
                        <Grid container justify="flex-end">
                            <Grid item xs={10}>
                                <TabList onChange={handleTabChange} aria-label="velg hvilken visning du vil fokusere på">
                                    <Tab label="Fordeling" value="1" />
                                    <Tab label="Trend" value="2" />
                                    <Tab label="Totalt" value="3" />
                                    <Tab label="Bedriftssøk" value="org" />
                                </TabList>
                            </Grid>
                            <Grid item xs={2}>
                                <button style={infoButtonStyle} onClick={(event) => { handleInfoClick(event, '1') }}>
                                    <InfoIcon style={{ color: 'white' }} />
                                </button>
                            </Grid>
                        </Grid>
                    </AppBar>
                    <CurrentInfoProvider>
                        <TabPanel value="1">
                            <Grid container>
                                <Grid item xs={12}>
                                    <BarReReChart />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="2">
                            <Grid container>
                                <Grid item xs={12}>
                                    <EmployeeReTrend />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="3">
                            <Grid container>
                                <Grid item xs={12}>
                                    <MonthlyReAggregated />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="org">
                            <Grid container>
                                <Grid item xs={12}> {
                                    match?.params?.orgId != null ? <OrgDetails orgId={match.params.orgId} /> : <OrgList />
                                }
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </CurrentInfoProvider>
                </TabContext>
            </Grid>
            <StyledPopover
                id={'1'}
                open={!!anchorEl['1']}
                anchorEl={anchorEl['1']}
                onClose={handleInfoClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={popOverStyle}
            >
                <div className="popover-container">
                    <BusinessCodePopup></BusinessCodePopup>
                </div>
            </StyledPopover>
        </div>
    )
}

export default Leads

import React from "react";
import { Slider, Typography, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { findClosestStepMarkValue, getClosestStepMarkValues, mapSliderValue, StepMark } from "../interface/StepMark";

interface Props {
    generatedMarks: StepMark[],
    sliderRange: number[],
    setSliderRange(val: number[]): void,
    maxValueRange: number
}


const BarDataLimiter: React.FC<Props> = ({generatedMarks, sliderRange, setSliderRange, maxValueRange}) => {
    
    const handleSliderChange = ((event: any, newValue: any) => {
        setSliderRange(newValue);
    });

    const valueLabelFormat = (value: number): string => {
        return mapSliderValue(value, generatedMarks).toString();
    }

    return (
        <>
            <Typography id="range-slider" gutterBottom>
                Antall ansatte
            </Typography>
            <Autocomplete
                size="small"
                id="from-search"
                filterOptions={(options: StepMark[], state) => {
                    const closestValue = findClosestStepMarkValue(state.inputValue, generatedMarks);
                    const closestValues: StepMark[] = getClosestStepMarkValues(closestValue, generatedMarks);
                    return closestValues;
                }}
                value={generatedMarks[sliderRange[0]] || { key: 0, value: 0 }}
                style={{ width: "120px", display: "inline-block" }}
                options={generatedMarks.sort((a: StepMark, b: StepMark) => a.value - b.value)}
                getOptionLabel={(option: StepMark) => option.value.toString()}
                onChange={(event: any, newValue: StepMark | null) => {
                    if (!newValue) {
                        return;
                    }
                    if (newValue.key > sliderRange[1]) {
                        setSliderRange([newValue.key, newValue.key]);
                    } else {
                        setSliderRange([newValue.key, sliderRange[1]]);
                    }
                }}
                renderInput={(params) => <TextField {...params} label="Fra" variant="outlined" />}
                selectOnFocus
            />

            <Autocomplete
                size="small"
                id="to-search"
                filterOptions={(options: StepMark[], state) => {
                    const closestValue = findClosestStepMarkValue(state.inputValue, generatedMarks);
                    const closestValues: StepMark[] = getClosestStepMarkValues(closestValue, generatedMarks);
                    return closestValues;
                }}
                value={generatedMarks[sliderRange[1]] || { key: 0, value: 0 }}
                style={{ width: "120px", display: "inline-block" }}
                options={generatedMarks.sort((a: StepMark, b: StepMark) => a.value - b.value)}
                getOptionLabel={(option: StepMark) => {
                    return option.value.toString()
                }}
                onChange={(event: any, newValue: StepMark | null) => {
                    if (!newValue) {
                        return;
                    }
                    if (newValue.key < sliderRange[0]) {
                        setSliderRange([newValue.key, newValue.key]);
                    } else {
                        setSliderRange([sliderRange[0], newValue.key]);
                    }
                }}
                renderInput={(params) => <TextField {...params} label="Til" variant="outlined" />}
                selectOnFocus
            />
            <Slider
                value={sliderRange}
                onChange={handleSliderChange}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay='auto'
                aria-labelledby="range-slider"
                min={0}
                max={maxValueRange}
            />
        </>
    )
}

export default BarDataLimiter;
import React from "react"
import { RouteComponentProps } from "react-router-dom";
import EsForm from "./EsForm";


const Assignments: React.FC<RouteComponentProps> = () => {
  return (
    <div className="container_loggedIn">
      <h1 style={{ margin: "auto" }}>Elastic Search</h1>
      <EsForm />
    </div>
  )
}

export default Assignments

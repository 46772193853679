import React from "react";
import { History } from "history";
import { ListItem, ListItemIcon, ListItemText, List } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import PageviewIcon from "@material-ui/icons/Pageview";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import GitHubIcon from "@material-ui/icons/GitHub";
import MenuBookIcon from "@material-ui/icons/MenuBook";

import { useGoogleAuth } from "../login/GoogleSignIn";

interface Props {
  history: History
  onClick?: () => void
}

export const Primary: React.FC<Props> = ({ history }) => {
  const itemsList = [
    {
      text: "Home",
      icon: <HomeIcon />,
      onClick: () => history.push("/home"),
    },
    {
      text: "Account",
      icon: <AccountBoxIcon />,
      onClick: () => history.push("/account"),
    },
    {
      text: "Tripletex",
      icon: <AccountBalanceIcon />,
      onClick: () => history.push("/tripletex"),
    },
    {
      text: "Assignments",
      icon: <AssessmentIcon />,
      onClick: () => history.push("/assignments"),
    },
    {
      text: "Kibana",
      icon: <PageviewIcon />,
      onClick: () => history.push("/kibana"),
    },
    {
      text: "Leads API",
      icon: <BusinessCenterIcon />,
      onClick: () => history.push("/leads"),
    },
  ]
  return (
    <List>
      {itemsList.map(item => {
        const { text, icon, onClick } = item
        return (
          <ListItem button key={text} onClick={onClick}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        )
      })}
    </List>
  )
}

export const Secondary: React.FC<Props> = ({ history, onClick }) => {
  const { signOut, googleUser } = useGoogleAuth()
  const logOut = async (his: History) => {
    if (onClick) {
      onClick()
    }
    const ok = await signOut()
    if (ok) {
//      navigate("/")
    }
  }
  return (
    <List>
      <ListItem
        button
        component="a"
        href={`https://mail.google.com/mail/u/?authuser=${googleUser?.profileObj?.email}`}
        target="blank"
      >
        <ListItemIcon>
          <MailIcon />
        </ListItemIcon>
        <ListItemText primary="Gmail" />
      </ListItem>
      <ListItem
        button
        component="a"
        href="https://github.com/elateas"
        target="blank"
      >
        <ListItemIcon>
          <GitHubIcon />
        </ListItemIcon>
        <ListItemText primary="GitHub" />
      </ListItem>
      <ListItem
        button
        component="a"
        href="https://phb.elate.no/#/"
        target="blank"
      >
        <ListItemIcon>
          <MenuBookIcon />
        </ListItemIcon>
        <ListItemText primary="Personalhåndbok" />
      </ListItem>
      <ListItem button onClick={() => logOut(history)}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Sign Out" />
      </ListItem>
    </List>
  )
}
